import { 
	Component, 
	Input, 
	OnInit, 
} from "@angular/core";

import { Router } from "@angular/router"

import { PopoverController } from "@ionic/angular";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";

import { TranslateService } from "@ngx-translate/core";

import { AppSettings } from "../app-settings";

import { OfferService } from "../services/offer.service";
import { UserService } from "../services/user.service";

import { RedeemComponent } from "./redeem.component";

@Component({
	selector: "offer-detail",
	templateUrl: "./offer-detail.component.html",
	styleUrls: ["./offer-detail.component.css"]
})
export class OfferDetailComponent implements OnInit {

	@Input() parent: any = null;
	@Input() offer: any = null;

	private APP_SETTINGS = AppSettings;

	private LOCALIZATION_PREFIX: string = "offer_detail.";

	constructor(
		private in_app_browser: InAppBrowser, 
		private offer_service: OfferService, 
		private popover_controller: PopoverController, 
		private translate_service: TranslateService, 
		private user_service: UserService, 
	) {
		//
	}

	ngOnInit(): void {
		//
	}

	private dismiss_this_modal(): void {
		if (this.parent) this.parent.dismiss_offer_detail_view();
	}

	private can_redeem_offer(offer: any): boolean {
		if (offer.id == -1) return false;
		return offer.num_points <= this.user_service.get_logged_in_user().total_points;
	}

	private redeem_popover: any = null;
	private async open_redeem_view(ev: any, offer: any) {
		if (!this.can_redeem_offer(offer)) return;
		
		this.redeem_popover = await this.popover_controller.create({
			component: RedeemComponent, 
			componentProps: {
		    	parent: this, 
		    	offer: offer, 
		    }, 
			cssClass: "redeem_popover",
			// event: ev,
			translucent: true
		});
		return await this.redeem_popover.present();
	}
	dismiss_redeem_view(): void {
		this.redeem_popover.dismiss().then(() => { this.redeem_popover = null; });
	}

}


