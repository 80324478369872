import { 
	Component, 
	Input, 
	OnInit, 
} from "@angular/core";

import { Router } from "@angular/router"

import { TranslateService } from "@ngx-translate/core";

import { Dialog } from "@capacitor/dialog";

import { OfferService } from "../services/offer.service";
import { UserService } from "../services/user.service";

@Component({
	selector: "redeem",
	templateUrl: "./redeem.component.html",
	styleUrls: ["./redeem.component.css"]
})
export class RedeemComponent implements OnInit {

	@Input() parent: any = null;
	@Input() offer: any = null;

	private LOCALIZATION_PREFIX: string = "redeem.";

	constructor(
		private offer_service: OfferService, 
		private translate_service: TranslateService, 
		private user_service: UserService, 
	) {
		//
	}

	ngOnInit(): void {
		//
	}

	private waiting_for_server_response: boolean = false;
	private redeem(): void {
		this.waiting_for_server_response = true;
		this.offer_service.redeem_offer(this.offer).subscribe(
			res => {
				if (res.success) {
					this.user_service.get_logged_in_user().total_points -= this.offer.num_points;
					this.waiting_for_server_response = false;
					if (this.parent) this.parent.dismiss_redeem_view();
				} else {
					Dialog.alert({
						title: "Error", 
						message: res.message
					});
					this.waiting_for_server_response = false;
				}
			}, 
			error => {
				//
			}
		);
	}

	private cancel(): void {
		if (this.parent) this.parent.dismiss_redeem_view();
	}

}


