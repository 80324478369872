import { 
	Component, 
	Input, 
	OnInit, 
	ViewChild, 
	ViewChildren, 
} from "@angular/core";

import { trigger, state, style, animate, transition } from "@angular/animations";

import { IonRefresher, ModalController } from "@ionic/angular";

import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core";

import { Utils } from "../utils";

import { ActionService } from "../services/action.service";
import { UserService } from "../services/user.service";

import { ActionDetailComponent } from "../action-detail/action-detail.component";
import { ActionScheduleComponent } from "../action-detail/action-schedule.component";
import { AddActionsPage } from "../add-actions/add-actions.page";
import { CommunityPage } from "../community/community.page";
import { MyActionsPage } from "../my-actions/my-actions.page";

@Component({
	selector: "action-drawer",
	templateUrl: "./action-drawer.component.html",
	styleUrls: ["./action-drawer.component.css"], 
	animations: [
	    trigger("slide_out", [
			transition(":leave", [
				animate("200ms linear", style({transform: "translateX(-100%)"}))
			]), 
		]), 
	], 
})
export class ActionDrawerComponent implements OnInit {

	public static instance: ActionDrawerComponent = null;

	private UTILS: any = Utils;

	private LOCALIZATION_PREFIX: string = "action_drawer.";

	@Input() parent: MyActionsPage;

	private action_library: Array<any> = null;
	private action_sort_type: string = "alpha";

	@ViewChildren(IonRefresher) refreshers: Array<IonRefresher>;

	private modal: any = null;

	constructor(
		private action_service: ActionService, 
		private modal_controller: ModalController, 
		private router: Router, 
		private translate_service: TranslateService, 
		private user_service: UserService, 
	) {
		// 
	}

	ngOnInit(): void {
		ActionDrawerComponent.instance = this;

		this.reload_action_library();
	}

	private get_language(): string {
		return navigator.language;
	}

	public reload_action_library(): void {
		this.action_library = null;
		this.action_service.load_action_library().subscribe(
			action_library => {
				this.action_library = action_library;
				this.refreshers.forEach(refresher => {
					refresher.complete();
				});
			}, 
			error => {
				//
			}
		);
	}

	get_filtered_action_library(nav_item: any): Array<any> {

		var actions = [];

		this.action_library.forEach(action => {
			if (action.is_suggested || action.liked) actions.push(action);
		});

		actions.sort((action1: any, action2: any) => {

			var field1, field2;

			if (this.action_sort_type == "alpha") {

				field1 = action1.name; field2 = action2.name;

			} else if (this.action_sort_type == "cost") {

				field1 = action1.cost_bucket; field2 = action2.cost_bucket;

			} else if (this.action_sort_type == "date") {

				field1 = 0;
				if (action1.hasOwnProperty("next_date") && action1.next_date != null) field1 += action1.next_date.getTime();
				if (action1.hasOwnProperty("start_time") && action1.start_time != null) field1 += action1.start_time * 1000;
				field2 = 0;
				if (action2.hasOwnProperty("next_date") && action2.next_date != null) field2 += action2.next_date.getTime();
				if (action2.hasOwnProperty("start_time") && action2.start_time != null) field2 += action2.start_time * 1000;

			} else if (this.action_sort_type == "pts") {

				field1 = action2.num_points; field2 = action1.num_points;

			}

			if (field1 > field2) {
	           return 1;
	        } else if (field1 < field2) {
	            return -1;
	        } else return 0;

		});
		
		return actions;
	}

	private async display_action_detail_modal(ev: any, action: any) {
		if (ev.target.classList.contains("action_selected_icon")) return;
		if (ev.target.classList.contains("action_icon_heart_empty")) return;
		if (ev.target.classList.contains("action_icon_heart_filled")) return;
		if (ev.target.classList.contains("action_icon_heart_empty")) return;
		if (ev.target.classList.contains("action_icon_plus")) return;
		if (ev.target.classList.contains("action_icon_minus")) return;

		this.modal = await this.modal_controller.create({
			component: ActionDetailComponent,
			componentProps: {
				action: action, 
		    	parent: this, 
		    }, 
			cssClass: "action_detail", 
		});
		return await this.modal.present();
	}
	private subscribe(action: any) {
		action.waiting_for_server_response = true;
		if (action.is_event) {
			this.toggle_action_subscribed(action, function() {
				action.waiting_for_server_response = false;
				action.subscribed = true;
			}, false);
		} else if (action.is_schedule_overridable) {
			let _this = this;
			this.toggle_action_subscribed(action, async function() {
				_this.modal = await _this.modal_controller.create({
					component: ActionScheduleComponent,
					componentProps: {
						action: action, 
				    	parent: _this, 
				    	display_close_button: false, 
				    }, 
					cssClass: "action_schedule", 
				});
				await _this.modal.present();
				action.waiting_for_server_response = false;
				action.subscribed = true;
			}, false);
		} else {
			this.toggle_action_subscribed(action, function() {
				action.waiting_for_server_response = false;
				action.subscribed = true;
			}, false);
		}
	}
	dismiss_modal(): void {
		if (this.modal) this.modal.dismiss().then(() => { this.modal = null; });
	}

	toggle_action_liked(action: any): void {
		if (action.liked) action.slide_out = true;
		this.action_service.toggle_action_liked(action).subscribe(
			success => {
				if (AddActionsPage.instance) AddActionsPage.instance.reload_action_library(null);
				if (CommunityPage.instance) CommunityPage.instance.reload_action_library();
				if (MyActionsPage.instance) MyActionsPage.instance.reload_actions();
				action.liked = !action.liked;
				action.slide_out = false;
			}, 
			error => {
				//
			}
		);
	}

	toggle_action_subscribed(action: any, invoke_on_success: any = null, change_action_object: boolean = true): void {
		if (change_action_object) action.subscribed = !action.subscribed;
		this.action_service.toggle_action_subscribed(action).subscribe(
			success => {
				if (AddActionsPage.instance) AddActionsPage.instance.reload_action_library(null);
				if (CommunityPage.instance) CommunityPage.instance.reload_action_library();
				if (MyActionsPage.instance) MyActionsPage.instance.reload_actions();
				if (invoke_on_success) invoke_on_success();
			}, 
			error => {
				//
			}
		);
	}

	private get_action_content_width(action: any): string {
		if (action.is_event) {
			return (window.innerWidth - 115) + "px";
		} else {
			return (window.innerWidth - 140) + "px";
		}
	}

	private get_action_num_points_str(action: any): string {
		return Math.round(action.num_points).toLocaleString();
	}
	private get_action_impact_str(action: any): string {
		return Math.round(action.impact).toLocaleString();
	}

	notify_listeners(): void {
		if (AddActionsPage.instance) AddActionsPage.instance.reload_action_library(null);
		if (CommunityPage.instance) CommunityPage.instance.reload_action_library();
		if (MyActionsPage.instance) MyActionsPage.instance.reload_actions();
	}

}


