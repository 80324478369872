import { NgModule } from "@angular/core";

import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

import { LogInComponent } from "./log-in/log-in.component";
import { WelcomeComponent } from "./welcome/welcome.component";

const routes: Routes = [
	{path: "", component: WelcomeComponent}, 
	{path: "survey/:section_index", component: WelcomeComponent}, 
	{path: "log-in", component: LogInComponent}, 
	{path: "tabs", loadChildren: () => import("./tabs/tabs.module").then(m => m.TabsPageModule)}, 
];
@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}


