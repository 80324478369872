import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";

import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { AppSettings } from "../app-settings";

@Injectable()
export class GamesService {

	constructor(
		private httpClient: HttpClient, 
		private router: Router
	) { }

	load_games_data(): Observable<any> {
		return this.httpClient.get(
			AppSettings.API_ENDPOINT + "compute-games-data", 
			{withCredentials: true}
		).pipe(
			map(res => {
				return res;
			}),
			catchError(error => {
				// alert("An error occurred.");
				return null;
			})
		);
	}

}


