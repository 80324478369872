import { 
	Component, 
	ElementRef, 
	Input, 
	OnInit, 
	ViewChild, 
	ViewChildren,
} from "@angular/core";

import { trigger, state, style, animate, transition } from "@angular/animations";

import { ActivatedRoute, Router } from "@angular/router";

import { IonRefresher, IonSlides, ModalController, PopoverController } from "@ionic/angular";

import { TranslateService } from "@ngx-translate/core";

import { Chart } from "chart.js";
import "chartjs-plugin-labels";

import { AppSettings } from "../app-settings";
import { Utils } from "../utils";

import { ImpactService } from "../services/impact.service";
import { SurveyService } from "../services/survey.service";
import { UserService } from "../services/user.service";

import { AppComponent } from "../app.component";
import { DualSliderPage } from "../dual-slider.page";

@Component({
	selector: "footprint",
	templateUrl: "./footprint.page.html",
	styleUrls: ["./footprint.page.css"], 
	animations: [
	    trigger("slide_up_down_state", [
	    	state("up", style({maxHeight: '0px'})),
	    	state("down", style({maxHeight: '700px'})),
			transition("up => down", animate("500ms linear")), 
			transition("down => up", animate("500ms linear")), 
	    ])
	]
})
export class FootprintPage extends DualSliderPage implements OnInit {

	public static instance: FootprintPage = null;

	@ViewChild("city_baseline_chart") city_baseline_chart: any;
	@ViewChild("footprint_chart") footprint_chart: any;
	@ViewChild("hogs_chart") hogs_chart: any;

	LOCALIZATION_PREFIX: string = "footprint.";
	private title: string = this.LOCALIZATION_PREFIX + "title";

	NAV_ITEMS = [
		{
			icon_class: "infinite_nav_icon_survey", 
			label_long: "nav_label_long_baseline", 
			label_short: "nav_label_short_baseline", 
		}, 
		{
			icon_class: "infinite_nav_icon_footprint", 
			label_long: "nav_label_long_footprint", 
			label_short: "nav_label_short_footprint", 
		}, 
		{
			icon_class: "infinite_nav_icon_hogs", 
			label_long: "nav_label_long_hogs", 
			label_short: "nav_label_short_hogs", 
		}, 
		{
			icon_class: "infinite_nav_icon_impact", 
			label_long: "nav_label_long_impact", 
			label_short: "nav_label_short_impact", 
		}, 
	];

	private NAV_SLIDER_OPTIONS: any = {
		loop: true, 
		slidesPerView: 4, 
		slideToClickedSlide: true, 
		// slidesOffsetAfter: 10, 
		// slidesOffsetBefore: 10, 
		watchSlidesProgress: true, 
		watchSlidesVisibility: true, 
	};
	MAIN_SLIDER_SPEED = 300;
	private MAIN_SLIDER_OPTIONS: any = {
		loop: true, 
		speed: this.MAIN_SLIDER_SPEED, 
	};

	tabs_header_collapsible: boolean = false;
	tabs_header_collapsed: boolean = false;

	INIT_BANNER_HEIGHT: number = 126;
	banner_height: number = this.INIT_BANNER_HEIGHT;

	INIT_MAIN_SLIDES_HEIGHT_ADJ = 230;
	main_slides_height_adj: number = this.INIT_MAIN_SLIDES_HEIGHT_ADJ;

	last_nav_index: number = this.NAV_ITEMS.length;

	private COLOR_LIBRARY_USER: Array<string> = ["#800000", "#778899", "#006400", "#9932CC", "#008B8B", "#D2691E"];

	private UTILS: any = Utils;

	@ViewChild("nav_slides") nav_slides: IonSlides;
	@ViewChild("main_slides") main_slides: IonSlides;

	@ViewChildren(IonRefresher) refreshers: Array<IonRefresher>;

	private should_display_user_data: boolean = false;

	private survey: any = null;

	private impact_data: any = null;

	private baseline_intro_hidden: boolean = true;
	private hogs_intro_hidden: boolean = true;

	constructor(
		private impact_service: ImpactService, 
		private modal_controller: ModalController, 
		private popover_controller: PopoverController, 
		private route: ActivatedRoute, 
		private router: Router, 
		private survey_service: SurveyService, 
		private translate_service: TranslateService, 
		private user_service: UserService, 
	) {
		super(translate_service);
	}

	ngOnInit(): void {

		FootprintPage.instance = this;

		super.translate_nav_labels();

		this.refresh_user();

		let _this = this;
		this.route.params.subscribe(params => {
			if (params.hasOwnProperty("tab_index")) setTimeout(function() {_this.slide_to(+(params.tab_index.substring(1,2)));}, 0);
		});

		let tab_index = AppComponent.get_notification_tab_index();
		if (tab_index != null) {
			setTimeout(() => {
				this.slide_to(tab_index);
			}, 0);
		}
		
	}

	private get_language(): string {
		return navigator.language;
	}

	private get_survey_sections_baseline(): Array<any> {
		let sections = [];
		this.survey.sections.forEach(section => {
			if (section.phase == "baseline") sections.push(section);
		});
		return sections;
	}
	private get_survey_sections_hogs(): Array<any> {
		let sections = [];
		this.survey.sections.forEach(section => {
			if (section.phase == "details") sections.push(section);
		});
		return sections;
	}

	private progress_percentage_circle_label_baseline(): string {
		var possible = 0;
		var completed = 0;
		this.survey.sections.forEach(section => {
			if (section.phase == "baseline") {
				possible += 1;
				if (section.is_complete) completed += 1;
			}
		});
		return Math.round(completed / possible * 100) + "%";
	}
	private progress_percentage_circle_label_hogs(): string {
		var possible = 0;
		var completed = 0;
		this.survey.sections.forEach(section => {
			if (section.phase == "details") {
				possible += 1;
				if (section.is_complete) completed += 1;
			}
		});
		return Math.round(completed / possible * 100) + "%";
	}
	private compute_progress_bar_width_baseline(): string {
		var possible = 0;
		var completed = 0;
		this.survey.sections.forEach(section => {
			if (section.phase == "baseline") {
				possible += 1;
				if (section.is_complete) completed += 1;
			}
		});
		if (completed == 0) return AppSettings.PROGRESS_BAR_COMPLETE_ZERO_WIDTH;
		if (completed == possible) return AppSettings.PROGRESS_BAR_COMPLETE_FULL_WIDTH;
		return Math.round(completed / possible * 100) + "%";
	}
	private compute_progress_bar_width_hogs(): string {
		var possible = 0;
		var completed = 0;
		this.survey.sections.forEach(section => {
			if (section.phase == "details") {
				possible += 1;
				if (section.is_complete) completed += 1;
			}
		});
		if (completed == 0) return AppSettings.PROGRESS_BAR_COMPLETE_ZERO_WIDTH;
		if (completed == possible) return AppSettings.PROGRESS_BAR_COMPLETE_FULL_WIDTH;
		return Math.round(completed / possible * 100) + "%";
	}

	public refresh_user(): void {
		this.should_display_user_data = false;
		this.user_service.load_logged_in_user().subscribe(
			user => {
				if (user != null) {
					this.should_display_user_data = true;
					let _this = this;
					setTimeout(function() {
						_this.generate_baseline_chart();
					}, 0);
					setTimeout(function() {
						_this.generate_footprint_chart();
					}, 0);
					setTimeout(function() {
						_this.generate_hogs_chart();
					}, 0);
				}
			}, 
			error => {
				//
			}
		);

		// TODO: This is a bit clumsy:
		this.survey_service.load_survey().subscribe(
			survey => {
				this.survey = survey;
			}, 
			error => {
				//
			}
		);

		this.impact_service.load_impact_data().subscribe(
			impact_data => {
				this.impact_data = impact_data;
			}, 
			error => {
				//
			}
		);
	}

	private generate_footprint_chart(): void {
		let city_baseline = this.user_service.get_logged_in_user().city_baseline;
		let user_baseline = this.user_service.get_logged_in_user().user_baseline;
		let reduction_by_resource = this.user_service.get_logged_in_user().ave_daily_co2_reduction_by_resource;

		let resource_labels = [];
		let emissions_data = [];
		for (var resource_index_city = 0; resource_index_city < city_baseline.length; resource_index_city ++) {

			resource_labels.push(city_baseline[resource_index_city].resource_name);

			// First find baseline emissions:
			var found = false;
			var emissions;
			for (var resource_index_user = 0; resource_index_user < user_baseline.length; resource_index_user ++) {
				if (city_baseline[resource_index_city].resource_ID == user_baseline[resource_index_user].resource_ID) {
					emissions = user_baseline[resource_index_user].emissions;
					found = true;
					break;
				}
			}
			if (!found) emissions = city_baseline[resource_index_city].emissions;

			// Now adjust emissions by reduction:
			for (var resource_index_reduction = 0; resource_index_reduction < reduction_by_resource.length; resource_index_reduction ++) {
				if (city_baseline[resource_index_city].resource_ID == reduction_by_resource[resource_index_reduction].resource_ID) {
					emissions -= reduction_by_resource[resource_index_reduction].reduction * 365 / 1000000;
					break;
				}
			}

			emissions_data.push(emissions);

		}

		let _this = this;
		_this.footprint_chart = new Chart(_this.footprint_chart.nativeElement, {
			type: "pie",
			data: {
				labels: resource_labels,
				datasets: [{
					data: emissions_data,
					backgroundColor: this.COLOR_LIBRARY_USER, 
				}], 
			}, 
			options: {
				aspectRatio: 1.5, 
				plugins: {
					labels: {
						render: "percentage", 
						position: "outside", 
						fontColor: "#000000",
					}, 
				}, 
				tooltips: {
	                callbacks: {
	                    label: function(tooltipItems, data) {
	                    	let emissions_rounded = (Math.round((data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index] as number) * 100) / 100).toLocaleString();
	                        return data.labels[tooltipItems.index] + ": " + emissions_rounded + " CO2e";
	                    }
	                }
	            },
			}, 
		});
	}

	private hogs_total: number = 0;
	private compute_hogs_total_str(): string {
		return (Math.round(this.hogs_total * 10) / 10).toLocaleString();
	}
	private compute_hogs_percent_str(): string {
		return Math.round(this.hogs_total / this.compute_user_baseline_sum() * 100).toLocaleString();
	}
	private generate_hogs_chart(): void {
		this.hogs_total = 0;
		
		let hogs = this.user_service.get_logged_in_user().user_hogs;

		let labels = [];
		let impact = [];
		let color_library = ["#800000", "#778899", "#006400", "#9932CC", "#008B8B"];
		let background_colors = [];
		for (var hog_index = 0; hog_index < Math.min(hogs.length, 5); hog_index ++) {
			let hog = hogs[hog_index];
			labels.push(hog.name);
			impact.push(hog.impact);
			background_colors.push(color_library[hog_index]);

			this.hogs_total += hog.impact;
		}

		let _this = this;
		// var _ = setInterval(function() {
		// 	if (_this.hogs_chart == null) return;
			_this.hogs_chart = new Chart(_this.hogs_chart.nativeElement, {
				type: "pie",
				data: {
					labels: labels,
					datasets: [{
						data: impact,
						backgroundColor: background_colors, 
					}], 
				}, 
				options: {
					aspectRatio: 1.5, 
					plugins: {
						labels: {
							render: "percentage", 
							position: "outside", 
							fontColor: "#000000",
						}, 
					}, 
					tooltips: {
		                callbacks: {
		                    label: function(tooltipItems, data) {
		                    	let emissions_rounded = (Math.round((data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index] as number) * 100) / 100).toLocaleString();
		                        return data.labels[tooltipItems.index] + ": " + emissions_rounded + " CO2e";
		                    }
		                }
		            },
				}, 
			});
		// 	clearInterval(_);
		// }, 1000);
	}

	private generate_baseline_chart(): void {
		let city_baseline = this.user_service.get_logged_in_user().city_baseline;
		let user_baseline = this.user_service.get_logged_in_user().user_baseline;

		let resource_labels = [];
		let emissions_data = [];
		let color_library_city = ["#909090", "#C0C0C0", "#909090", "#C0C0C0", "#909090", "#C0C0C0"];
		let background_colors = [];
		for (var resource_index_city = 0; resource_index_city < city_baseline.length; resource_index_city ++) {
			resource_labels.push(city_baseline[resource_index_city].resource_name);
			var found = false;
			for (var resource_index_user = 0; resource_index_user < user_baseline.length; resource_index_user ++) {
				if (city_baseline[resource_index_city].resource_ID == user_baseline[resource_index_user].resource_ID) {
					emissions_data.push(user_baseline[resource_index_user].emissions);
					background_colors.push(this.COLOR_LIBRARY_USER[resource_index_user]);
					found = true;
					break;
				}
			}
			if (!found) {
				emissions_data.push(city_baseline[resource_index_city].emissions);
				background_colors.push(color_library_city[resource_index_city]);
			}
		}
		var emissions_data_sum = 0;
		emissions_data.forEach(datum => {
			emissions_data_sum += datum;
		});

		let _this = this;
		// var _ = setInterval(function() {
		// 	if (_this.city_baseline_chart == null) return;
			_this.city_baseline_chart = new Chart(_this.city_baseline_chart.nativeElement, {
				type: "pie",
				data: {
					labels: resource_labels,
					datasets: [{
						data: emissions_data,
						backgroundColor: background_colors, 
					}], 
				}, 
				options: {
					aspectRatio: 1.5, 
					legend: {
						display: false, 
					}, 
					plugins: {
						labels: {
							arc: true, 
							fontSize: 10, 
							render: function(args) {
								return args.label;
							}, 
							overlap: false, 
							position: "outside", 
							fontColor: "#000000",
						}, 
					}, 
					tooltips: {
		                callbacks: {
		                    label: function(tooltipItems, data) {
		                    	let emissions_rounded = (Math.round((data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index] as number) * 100) / 100).toLocaleString();
		                    	let percentage_rounded = (Math.round((data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index] as number) / emissions_data_sum * 100)).toLocaleString();
		                        return data.labels[tooltipItems.index] + ": " + emissions_rounded + " CO2e, " + percentage_rounded + "%";
		                        // return emissions_rounded + " CO2e";
		                    }
		                }
		            },
				}, 
			});
		// 	clearInterval(_);
		// }, 1000);
	}

	private compute_city_baseline_sum_str(): string {
		let city_baseline = this.user_service.get_logged_in_user().city_baseline;
		var sum = 0;
		for (var resource_index = 0; resource_index < city_baseline.length; resource_index ++) {
			sum += city_baseline[resource_index].emissions;
		}
		return (Math.round(sum * 100) / 100).toLocaleString();
	}

	private compute_user_baseline_sum(): number {
		let city_baseline = this.user_service.get_logged_in_user().city_baseline;
		let user_baseline = this.user_service.get_logged_in_user().user_baseline;
		var sum = 0;
		for (var resource_index_city = 0; resource_index_city < city_baseline.length; resource_index_city ++) {
			var found = false;
			for (var resource_index_user = 0; resource_index_user < user_baseline.length; resource_index_user ++) {
				if (city_baseline[resource_index_city].resource_ID == user_baseline[resource_index_user].resource_ID) {
					sum += user_baseline[resource_index_user].emissions;
					found = true;
					break;
				}
			}
			if (!found) sum += city_baseline[resource_index_city].emissions;
		}
		return sum;
	}
	private compute_user_baseline_sum_str(): string {
		return Utils.compute_rounded_str(this.compute_user_baseline_sum(), 2);
	}

	private compute_ave_daily_reduction_adjusted(): number {
		return this.user_service.get_logged_in_user().ave_daily_co2_reduction * 365 / 1000000;
	}
	
	private compute_user_baseline_reduction_str(): string {
		return Utils.compute_rounded_str(this.compute_ave_daily_reduction_adjusted(), 2);
	}

	private compute_user_current_footprint(): number {
		return this.compute_user_baseline_sum() - this.compute_ave_daily_reduction_adjusted();
	}
	private compute_user_current_footprint_str(): string {
		return Utils.compute_rounded_str(this.compute_user_current_footprint(), 2);
	}

	private compute_user_current_footprint_delta_str(): string {
		let baseline = this.compute_user_baseline_sum();
		let footprint = this.compute_user_current_footprint();
		return Utils.compute_rounded_str((footprint - baseline) / baseline * 100, 2) + "%";
	}

	private get_num_days_remaining_str(): string {
		let num_days_remaining = Math.round(this.user_service.get_logged_in_user().num_days_remaining);
		if (num_days_remaining < 0) return "0";
		return num_days_remaining.toString();
	}

}


