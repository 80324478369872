import { 
	Component, 
	OnInit
} from "@angular/core";

import { Router } from "@angular/router"

import { FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";

import { Platform } from "@ionic/angular";

import { TranslateService } from "@ngx-translate/core";

import { Dialog } from "@capacitor/dialog";

declare var PTAGH_DEVICE: string;

import { StorageService } from "../services/storage.service";
import { UserService } from "../services/user.service";

import { AppComponent } from "../app.component";
import { FootprintPage } from "../footprint/footprint.page";
import { WelcomeComponent } from "../welcome/welcome.component";

@Component({
	selector: "log-in",
	templateUrl: "./log-in.component.html",
	styleUrls: ["./log-in.component.css"]
})
export class LogInComponent implements OnInit {

	private email_control: FormControl = new FormControl("", [Validators.required, Validators.email]);
	private password_control = new FormControl("", [Validators.required, Validators.minLength(8)]);
	// private password_control: FormControl = new FormControl("", [Validators.required]);

	private login_form: FormGroup = new FormGroup({
		email: this.email_control, 
		password: this.password_control, 
	});

	private hide_password: boolean = true;

	private LOCALIZATION_PREFIX: string = "login.";

	constructor(
		private platform: Platform, 
		private router: Router, 
		private storage_service: StorageService, 
		private translate_service: TranslateService, 
		private user_service: UserService, 
	) {
		// Do nothing.
	}

	ngOnInit(): void {
		this.user_service.load_logged_in_user().subscribe(
			async user => {
				if (user != null) {
					if (FootprintPage.instance) FootprintPage.instance.refresh_user();
					this.router.navigate(["/tabs/my-actions"]);
				} else {
					let existing_email = await this.storage_service.get("login.email");
					let existing_password = await this.storage_service.get("login.password");
					if (existing_email && existing_password) {
						this.email_control.setValue(existing_email);
						this.password_control.setValue(existing_password);
						// this.submit_login_form();
					}
				}
			}, 
			error => {
				//
			}
		);
	}

	get_login_form_margin_top(): string {
		if (this.platform.platforms().includes("ios")) {
			return "50px";
		} else {
			return "0px";
		}
	}

	private get_error_message_email(): string {
		return this.email_control.hasError("required") ? "form_missing_value" : this.email_control.hasError("email") ? "form_invalid_email" : "";
	}
	private get_error_message_password(): string {
		return this.password_control.hasError("required") ? "form_missing_value" : this.password_control.hasError("minlength") ? "form_at_least_8_chars" : "";
	}

	private logging_in = false;
	private submit_login_form(): void {
		this.logging_in = true;
		let email = this.email_control.value;
		let password = this.password_control.value;
		this.user_service.log_in(email, password).subscribe(
			user => {
				this.logging_in = false;
				if (user == null) {
					this.translate_service.get(this.LOCALIZATION_PREFIX + "login_error").subscribe((res: string) => {
					    Dialog.alert({
							title: "Login Error", 
							message: res
						});
					});
				} else {
					this.storage_service.set("login.email", email);
					this.storage_service.set("login.password", password);
					let post_login_url = AppComponent.get_notification_post_login_url();
					console.log("post_login_url: " + post_login_url);
					if (user.has_finished_setup) {
						if (FootprintPage.instance) FootprintPage.instance.refresh_user();
						if (post_login_url) {
							this.router.navigate([ post_login_url ]);
						} else {
							this.router.navigate([ "/tabs/my-actions" ]);
						}
					} else {
						if (WelcomeComponent.instance) WelcomeComponent.instance.update_state();
						this.router.navigate([ "/" ]);
					}
				}
			}, 
			error => {
				this.logging_in = false;
				this.translate_service.get(this.LOCALIZATION_PREFIX + "login_error").subscribe((res: string) => {
				    Dialog.alert({
						title: "Login Error", 
						message: res
					});
				});
			}
		);
	}

	private requesting_password_reset = false;
	private request_password_reset(): void {
		if (this.requesting_password_reset) return;

		if (this.email_control.hasError("required")) {
			this.translate_service.get(this.LOCALIZATION_PREFIX + "pw_reset_instructions").subscribe((res: string) => {
				Dialog.alert({
					title: "Password Reset", 
					message: res
				});
			});
			return;
		}
		if (this.email_control.hasError("email")) {
			this.translate_service.get(this.LOCALIZATION_PREFIX + "pw_reset_invalid_email").subscribe((res: string) => {
			    Dialog.alert({
					title: "Password Reset", 
					message: res
				});
			});
			return;
		}

		this.requesting_password_reset = true;
		this.user_service.request_password_reset(this.email_control.value).subscribe(
			success => {
				this.requesting_password_reset = false;
				if (success) {
					this.translate_service.get(this.LOCALIZATION_PREFIX + "pw_reset_sent").subscribe((res: string) => {
					    Dialog.alert({
							title: "Password Reset", 
							message: res
						});
					});
				}
			}, 
			error => {
				this.requesting_password_reset = false;
			}
		);
	}

}


