import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";

import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { AppSettings } from "../app-settings";

@Injectable()
export class AnnouncementService {

	constructor(
		private httpClient: HttpClient, 
		private router: Router
	) { }

	load_announcements(): Observable<any> {
		return this.httpClient.get(
			AppSettings.API_ENDPOINT + "get-announcements?date=" + new Date().toISOString().slice(0, 10), 
			{withCredentials: true}
		).pipe(
			map(res => {
				return (res as any).announcements;
			}),
			catchError(error => {
				// alert("An error occurred.");
				return null;
			})
		);
	}

}


