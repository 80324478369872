import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";

import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { AppSettings } from "../app-settings";

@Injectable()
export class OfferService {

	constructor(
		private httpClient: HttpClient, 
		private router: Router
	) { }

	load_available_offers(): Observable<any> {
		return this.httpClient.get(
			AppSettings.API_ENDPOINT + "get-available-offers", 
			{withCredentials: true}
		).pipe(
			map(res => {
				return (res as any).offers;
			}),
			catchError(error => {
				// alert("An error occurred.");
				return null;
			})
		);
	}

	toggle_offer_liked(offer: any): Observable<any> {
		return this.httpClient.post(
			AppSettings.API_ENDPOINT + "toggle-offer-liked", 
			JSON.stringify({offer_id: offer.id}), 
			{headers: AppSettings.POST_HEADERS, withCredentials: true}
		).pipe(
			map(res => {
				return (res as any).success;
			}),
			catchError(error => {
				// alert("An error occurred.");
				return null;
			})
		);
	}

	redeem_offer(offer: any): Observable<any> {
		return this.httpClient.post(
			AppSettings.API_ENDPOINT + "redeem-offer", 
			JSON.stringify({offer_id: offer.id}), 
			{headers: AppSettings.POST_HEADERS, withCredentials: true}
		).pipe(
			map(res => {
				return res;
			}),
			catchError(error => {
				// alert("An error occurred.");
				return null;
			})
		);
	}

}


