import { 
	Component, 
	Input, 
	OnInit, 
} from "@angular/core";

import { Router } from "@angular/router"

import { PopoverController } from "@ionic/angular";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";

import { TranslateService } from "@ngx-translate/core";

import { AppSettings } from "../app-settings";

import { OfferService } from "../services/offer.service";
import { UserService } from "../services/user.service";

import { CommunityPage } from "./community.page";

@Component({
	selector: "headline-detail",
	templateUrl: "./headline-detail.component.html",
	styleUrls: ["./headline-detail.component.css"]
})
export class HeadlineDetailComponent implements OnInit {

	@Input() parent: any = null;
	@Input() headline: any = null;

	private APP_SETTINGS = AppSettings;

	private LOCALIZATION_PREFIX: string = "headline.";

	constructor(
		private in_app_browser: InAppBrowser, 
		private offer_service: OfferService, 
		private popover_controller: PopoverController, 
		private translate_service: TranslateService, 
		private user_service: UserService, 
	) {
		//
	}

	ngOnInit(): void {
		//
	}

}


