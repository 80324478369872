import { 
	Component, 
	ElementRef, 
	Input, 
	OnInit, 
	ViewChild, 
	ViewChildren,
} from "@angular/core";

import { Router } from "@angular/router";

import { IonInput, IonRefresher, IonSlides, ModalController, PopoverController } from "@ionic/angular";

import { TranslateService } from "@ngx-translate/core";

import { OfferService } from "../services/offer.service";
import { UserService } from "../services/user.service";

import { DualSliderPage } from "../dual-slider.page";

import { AppComponent } from "../app.component";
import { OfferDetailComponent } from "./offer-detail.component";
import { RedeemComponent } from "./redeem.component";

@Component({
	selector: "wallet",
	templateUrl: "./wallet.page.html",
	styleUrls: ["./wallet.page.css"]
})
export class WalletPage extends DualSliderPage implements OnInit {

	public static instance: WalletPage = null;

	LOCALIZATION_PREFIX: string = "wallet.";
	private title: string = this.LOCALIZATION_PREFIX + "title";

	NAV_ITEMS = [
		{
			icon_class: "infinite_nav_icon_new", 
			label_long: "nav_label_long_new", 
			label_short: "nav_label_short_new", 
			filter_type: "new", 
		}, 
		{
			icon_class: "infinite_nav_icon_favs", 
			label_long: "nav_label_long_favs", 
			label_short: "nav_label_short_favs", 
			filter_type: "favs", 
		}, 
		{
			icon_class: "infinite_nav_icon_sand_watch", 
			label_long: "nav_label_long_expires_soon", 
			label_short: "nav_label_short_expires_soon", 
			filter_type: "expires_soon", 
		}, 
		{
			icon_class: "infinite_nav_icon_online_store", 
			label_long: "nav_label_long_biz_list", 
			label_short: "nav_label_short_biz_list", 
		}, 
		{
			icon_class: "infinite_nav_icon_tableware", 
			label_long: "nav_label_long_dining", 
			label_short: "nav_label_short_dining", 
			resource_type: "dining", 
		}, 
		{
			icon_class: "infinite_nav_icon_apple", 
			label_long: "nav_label_long_food", 
			label_short: "nav_label_short_food", 
			resource_type: "food", 
		}, 
		{
			icon_class: "infinite_nav_icon_theatre_mask", 
			label_long: "nav_label_long_fun", 
			label_short: "nav_label_short_fun", 
			resource_type: "fun", 
		}, 
		{
			icon_class: "infinite_nav_icon_clothes", 
			label_long: "nav_label_long_goods", 
			label_short: "nav_label_short_goods", 
			resource_type: "goods", 
		}, 
		{
			icon_class: "infinite_nav_icon_barbershop", 
			label_long: "nav_label_long_services", 
			label_short: "nav_label_short_services", 
			resource_type: "services", 
		}, 
		{
			icon_class: "infinite_nav_icon_suv", 
			label_long: "nav_label_long_transport", 
			label_short: "nav_label_short_transport", 
			resource_type: "transport", 
		}, 
		{
			icon_class: "infinite_nav_icon_box", 
			label_long: "nav_label_long_other", 
			label_short: "nav_label_short_other", 
			resource_type: "other", 
		}, 
	];

	private NAV_SLIDER_OPTIONS: any = {
		loop: true, 
		slidesPerView: "auto", 
		slideToClickedSlide: true, 
		slidesOffsetAfter: 10, 
		slidesOffsetBefore: 10, 
		spaceBetween: 10, 
		watchSlidesProgress: true, 
		watchSlidesVisibility: true, 
	};
	MAIN_SLIDER_SPEED = 300;
	private MAIN_SLIDER_OPTIONS: any = {
		loop: true, 
		speed: this.MAIN_SLIDER_SPEED, 
	};

	private available_offers: Array<any> = null;

	tabs_header_collapsible: boolean = true;
	tabs_header_collapsed: boolean = false;

	INIT_BANNER_HEIGHT: number = 126;
	banner_height: number = this.INIT_BANNER_HEIGHT;

	INIT_MAIN_SLIDES_HEIGHT_ADJ = 230; // We add the height of #search_bar in ngOnInit. 230 is the sum of the heights of all the other non-slide elements in the primary ion-content.
	main_slides_height_adj: number = this.INIT_MAIN_SLIDES_HEIGHT_ADJ;

	last_nav_index: number = this.NAV_ITEMS.length;

	private offer_sort_type: string = "alpha";

	private search_bar_value: string = "";

	@ViewChild("search_bar") search_bar: IonInput;
	@ViewChild("search_bar_container") search_bar_container: ElementRef;
	@ViewChild("nav_slides") nav_slides: IonSlides;
	@ViewChild("main_slides") main_slides: IonSlides;

	@ViewChildren(IonRefresher) refreshers: Array<IonRefresher>;

	constructor(
		private modal_controller: ModalController, 
		private offer_service: OfferService, 
		private popover_controller: PopoverController, 
		private router: Router, 
		private translate_service: TranslateService, 
		private user_service: UserService, 
	) {
		super(translate_service);
	}

	ngOnInit(): void {

		WalletPage.instance = this;

		// Hack because IonInput doesn't appear to immediately render:
		let _this = this;
		let _ = setInterval(function() {
			if (_this.search_bar_container.nativeElement.offsetHeight > 0) {
				_this.main_slides_height_adj += _this.search_bar_container.nativeElement.offsetHeight;
				clearInterval(_);
			}
 		}, 100);

		super.translate_nav_labels();
		this.reload_available_offers();

		let tab_index = AppComponent.get_notification_tab_index();
		if (tab_index != null) {
			setTimeout(() => {
				this.slide_to(tab_index);
			}, 0);
		}

	}

	private search_bar_active: boolean = false;
	private activate_search_bar(): void {
		this.search_bar_active = true;
		this.search_bar.setFocus();
	}
	private search_bar_focus(): void {
		this.nav_slides.slideTo(3); // TODO: Un-hard-code this index.
	}
	private search_bar_blur(): void {
		if (this.search_bar_value == "") this.search_bar_active = false;
	}

	private offer_detail_view: any = null;
	private async open_offer_detail_view(ev: any, offer: any) {
		if (ev.target.classList.contains("offer_icon")) return;
		if (ev.target.classList.contains("offer_redeem")) return;
		this.offer_detail_view = await this.modal_controller.create({
			component: OfferDetailComponent,
			componentProps: {
		    	parent: this, 
		    	offer: offer, 
		    }, 
			cssClass: "offer_detail", 
		});
		return await this.offer_detail_view.present();
	}
	dismiss_offer_detail_view(): void {
		this.offer_detail_view.dismiss().then(() => { this.offer_detail_view = null; });
	}

	private can_redeem_offer(offer: any): boolean {
		return offer.num_points <= this.user_service.get_logged_in_user().total_points;
	}

	private redeem_view: any = null;
	private async open_redeem_view(ev: any, offer: any) {
		if (!this.can_redeem_offer(offer)) return;

		this.redeem_view = await this.popover_controller.create({
			component: RedeemComponent, 
			componentProps: {
		    	parent: this, 
		    	offer: offer, 
		    }, 
			cssClass: "redeem_popover",
			// event: ev,
			translucent: true
		});
		return await this.redeem_view.present();
	}
	dismiss_redeem_view(): void {
		this.redeem_view.dismiss().then(() => { this.redeem_view = null; });
	}

	private toggle_offer_liked(ev: any, offer: any): void {
		offer.liked = !offer.liked;
		super.update_duplicate_slides();
		this.offer_service.toggle_offer_liked(offer).subscribe(
			success => {
				//
			}, 
			error => {
				//
			}
		);
	}

	get_filtered_available_offers(nav_item: any): Array<any> {

		let offers = [];

		if (nav_item.hasOwnProperty("resource_type")) {

			this.available_offers.forEach(offer => {
				if (offer.resource_types.includes(nav_item.resource_type)) offers.push(offer);
			});

		} else if (nav_item.hasOwnProperty("filter_type")) {

			if (nav_item.filter_type == "favs") {

				this.available_offers.forEach(offer => {
					if (offer.liked) offers.push(offer);
				});

			} else if (nav_item.filter_type == "new") {

				let now = Math.round(Date.now() / 1000);
				let thirty_days = 60*60*24*30;
				this.available_offers.forEach(offer => {
					if (now - offer.timestamp <= thirty_days) offers.push(offer);
				});

			} else if (nav_item.filter_type == "expires_soon") {

				let now = Math.round(Date.now() / 1000);
				let two_weeks = 60*60*24*30;
				this.available_offers.forEach(offer => {
					if (offer.expiry_timestamp - now <= two_weeks) offers.push(offer);
				});

			}

		} else { // Full business and offer list:

			if (this.search_bar_value.length > 0) {

				let search_str = this.search_bar_value.toLowerCase();
				this.available_offers.forEach(offer => {
					if (offer.business.name.toLowerCase().indexOf(search_str) > -1) {
						offers.push(offer);
					} else if (offer.headline.toLowerCase().indexOf(search_str) > -1) {
						offers.push(offer);
					}
				});

			} else {

				offers = this.available_offers;

			}

		}

		offers.sort((offer1: any, offer2: any) => {

			var field1, field2;

			if (this.offer_sort_type == "alpha") {

				if (offer1.business.name != offer2.business.name) {
					field1 = offer1.business.name; field2 = offer2.business.name;
				} else {
					field1 = offer1.headline; field2 = offer2.headline;
				}

				if (offer1.id == -1) field1 = "ZZZZZZZZ" + offer1.business.name;
				if (offer2.id == -1) field2 = "ZZZZZZZZ" + offer2.business.name;

			} else if (this.offer_sort_type == "pts") {

				field1 = offer1.num_points; field2 = offer2.num_points;

				if (offer1.id == -1) field1 = 99999999999;
				if (offer2.id == -1) field2 = 99999999999;

			} else if (this.offer_sort_type == "expiry") {

				field1 = offer1.expiry; field2 = offer2.expiry;

				if (offer1.id == -1) field1 = "9999-99-99";
				if (offer2.id == -1) field2 = "9999-99-99";

			} else if (this.offer_sort_type == "green_rating") {

				field1 = offer1.business.green_rating * -1; field2 = offer2.business.green_rating * -1;

			}

			if (field1 > field2) {
	           return 1;
	        } else if (field1 < field2) {
	            return -1;
	        } else return 0;

		});

		return offers;

	}

	public reload_available_offers(): void {
		this.available_offers = null;
		this.offer_service.load_available_offers().subscribe(
			offers => {
				this.available_offers = offers;
				this.refreshers.forEach(refresher => {
					refresher.complete();
				});
				super.update_duplicate_slides();
			}, 
			error => {
				//
			}
		);
	}

}


