import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from "@angular/common/http";
import { RouteReuseStrategy } from "@angular/router";

import { TranslateModule, TranslateLoader, TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import * as Hammer from "hammerjs";
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG, HammerModule } from "@angular/platform-browser";

import { IonicStorageModule } from "@ionic/storage-angular";

import { IonicModule, IonicRouteStrategy, ModalController } from "@ionic/angular";

import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { Network } from "@awesome-cordova-plugins/network/ngx";

import { AppRoutingModule } from "./app-routing.module";

import { AuthInterceptor } from "./auth-interceptor";

import { SafePipe } from "./safe.pipe";

import { ActionService } from "./services/action.service";
import { AnnouncementService } from "./services/announcement.service";
import { GamesService } from "./services/games.service";
import { HeadlineService } from "./services/headline.service";
import { ImpactService } from "./services/impact.service";
import { OfferService } from "./services/offer.service";
import { StorageService } from "./services/storage.service";
import { SurveyService } from "./services/survey.service";
import { UserService } from "./services/user.service";

import { AppComponent } from "./app.component";
import { LogInComponent } from "./log-in/log-in.component";
import { WelcomeComponent } from "./welcome/welcome.component";

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export class MyHammerConfig extends HammerGestureConfig  {
	overrides = <any>{
		"swipe": { direction: Hammer.DIRECTION_ALL }
	}
}

@NgModule({
	declarations: [
		AppComponent, 
		LogInComponent, 
		SafePipe, 
		WelcomeComponent, 
	],
	entryComponents: [],
	imports: [
		AppRoutingModule, 
		CommonModule, 
		BrowserModule, 
		BrowserAnimationsModule, 
		FormsModule, 
		HammerModule, 
		HttpClientModule, 
		IonicModule.forRoot(), 
		IonicStorageModule.forRoot(), 
		ReactiveFormsModule, 
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (createTranslateLoader),
				deps: [HttpClient]
			}
	    }), 
	],
	providers: [
		ActionService, 
		AnnouncementService, 
		GamesService, 
		HeadlineService, 
		ImpactService, 
		InAppBrowser, 
		ModalController, 
		Network, 
		OfferService, 
		SafePipe, 
		SplashScreen, 
		StatusBar, 
		StorageService, 
		SurveyService, 
		UserService, 
		{ provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig }, 
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }, 
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, 
	],
	bootstrap: [AppComponent]
})
export class AppModule {}


