import { 
	Component, 
	Input, 
	OnInit, 
	ViewChild, 
	ViewChildren,
} from "@angular/core";

import { Router } from "@angular/router";

import { IonRefresher, IonSlides, ModalController, PopoverController } from "@ionic/angular";

import { TranslateService } from "@ngx-translate/core";

import { AppSettings } from "../app-settings";

import { ActionService } from "../services/action.service";
import { HeadlineService } from "../services/headline.service";
import { UserService } from "../services/user.service";

import { ActionDetailComponent } from "../action-detail/action-detail.component";
import { ActionDrawerComponent } from "../my-actions/action-drawer.component";
import { AddActionsPage } from "../add-actions/add-actions.page";
import { AppComponent } from "../app.component";
import { DualSliderPage } from "../dual-slider.page";
import { HeadlineDetailComponent } from "./headline-detail.component";
import { MyActionsPage } from "../my-actions/my-actions.page";

@Component({
	selector: "community",
	templateUrl: "./community.page.html",
	styleUrls: ["./community.page.css"]
})
export class CommunityPage extends DualSliderPage implements OnInit {

	public static instance: CommunityPage = null;

	LOCALIZATION_PREFIX: string = "community.";
	private title: string = this.LOCALIZATION_PREFIX + "title";

	NAV_ITEMS = [
		{
			icon_class: "infinite_nav_icon_bullhorn", 
			label_long: "nav_label_long_headlines", 
			label_short: "nav_label_short_headlines", 
		}, 
		{
			icon_class: "infinite_nav_icon_calendar", 
			label_long: "nav_label_long_events", 
			label_short: "nav_label_short_events", 
		}, 
		{
			icon_class: "infinite_nav_icon_qa", 
			label_long: "nav_label_long_qa", 
			label_short: "nav_label_short_qa", 
		}, 
		{
			icon_class: "infinite_nav_icon_household", 
			label_long: "nav_label_long_household", 
			label_short: "nav_label_short_household", 
		}, 
		{
			icon_class: "infinite_nav_icon_input", 
			label_long: "nav_label_long_input", 
			label_short: "nav_label_short_input", 
		}, 
	];

	private NAV_SLIDER_OPTIONS: any = {
		loop: true, 
		slidesPerView: 5, 
		slideToClickedSlide: true, 
		// slidesOffsetAfter: 10, 
		// slidesOffsetBefore: 10, 
		watchSlidesProgress: true, 
		watchSlidesVisibility: true, 
	};
	MAIN_SLIDER_SPEED = 300;
	private MAIN_SLIDER_OPTIONS: any = {
		loop: true, 
		speed: this.MAIN_SLIDER_SPEED, 
	};

	tabs_header_collapsible: boolean = false;
	tabs_header_collapsed: boolean = false;

	INIT_BANNER_HEIGHT: number = 126;
	banner_height: number = this.INIT_BANNER_HEIGHT;

	INIT_MAIN_SLIDES_HEIGHT_ADJ = 230;
	main_slides_height_adj: number = this.INIT_MAIN_SLIDES_HEIGHT_ADJ;

	last_nav_index: number = this.NAV_ITEMS.length;

	private challenge_household_card_circle_index: number = 0;

	@ViewChild("nav_slides") nav_slides: IonSlides;
	@ViewChild("main_slides") main_slides: IonSlides;

	@ViewChildren(IonRefresher) refreshers: Array<IonRefresher>;

	private APP_SETTINGS = AppSettings;

	private headline_sort_type: string = "date";

	private headlines: Array<any> = null;

	private action_library: Array<any> = null;
	private action_sort_type: string = "alpha";

	private modal: any = null;

	constructor(
		private action_service: ActionService, 
		private headline_service: HeadlineService, 
		private modal_controller: ModalController, 
		private popover_controller: PopoverController, 
		private router: Router, 
		private translate_service: TranslateService, 
		private user_service: UserService, 
	) {
		super(translate_service);
	}

	ngOnInit(): void {

		CommunityPage.instance = this;

		super.translate_nav_labels();

		this.reload_headlines();
		this.reload_action_library();

		let tab_index = AppComponent.get_notification_tab_index();
		if (tab_index != null) {
			setTimeout(() => {
				this.slide_to(tab_index);
			}, 0);
		}

	}

	private async display_action_detail_modal(ev: any, action: any) {
		if (ev.target.classList.contains("action_icon_heart_filled")) return;
		if (ev.target.classList.contains("action_icon_heart_empty")) return;
		if (ev.target.classList.contains("action_icon_plus")) return;
		if (ev.target.classList.contains("action_icon_minus")) return;

		this.modal = await this.modal_controller.create({
			component: ActionDetailComponent,
			componentProps: {
				action: action, 
		    	parent: this, 
		    }, 
			cssClass: "action_detail", 
		});
		return await this.modal.present();
	}
	private subscribe(action: any) {
		action.waiting_for_server_response = true;
		this.toggle_action_subscribed(action, function() {
			action.waiting_for_server_response = false;
			action.subscribed = true;
		}, false);
	}
	dismiss_modal(): void {
		if (this.modal) this.modal.dismiss().then(() => { this.modal = null; });
	}
	get_filtered_action_library(): Array<any> {

		var actions = [];
		this.action_library.forEach(action => {
			if (action.is_event && action.hasOwnProperty("next_date")) actions.push(action);
		});

		actions.sort((action1: any, action2: any) => {

			var field1, field2;

			if (this.action_sort_type == "alpha") {

				field1 = action1.name; field2 = action2.name;

			} else if (this.action_sort_type == "date") {

				field1 = 0;
				if (action1.hasOwnProperty("next_date") && action1.next_date != null) field1 += action1.next_date.getTime();
				if (action1.hasOwnProperty("start_time") && action1.start_time != null) field1 += action1.start_time * 1000;
				field2 = 0;
				if (action2.hasOwnProperty("next_date") && action2.next_date != null) field2 += action2.next_date.getTime();
				if (action2.hasOwnProperty("start_time") && action2.start_time != null) field2 += action2.start_time * 1000;

			}

			if (field1 > field2) {
	           return 1;
	        } else if (field1 < field2) {
	            return -1;
	        } else return 0;

		});

		return actions;

	}
	private get_action_content_width(): string {
		return (window.innerWidth - 115) + "px";
	}
	private get_action_num_points_str(action: any): string {
		return Math.round(action.num_points).toLocaleString();
	}
	private get_action_impact_str(action: any): string {
		return Math.round(action.impact).toLocaleString();
	}
	public reload_action_library(): void {
		this.action_library = null;
		this.action_service.load_action_library().subscribe(
			action_library => {
				this.action_library = action_library;
				this.refreshers.forEach(refresher => {
					refresher.complete();
				});
				super.update_duplicate_slides();
			}, 
			error => {
				//
			}
		);
	}
	toggle_action_liked(action: any): void {
		action.liked = !action.liked;
		this.action_service.toggle_action_liked(action).subscribe(
			success => {
				if (ActionDrawerComponent.instance) ActionDrawerComponent.instance.reload_action_library();
				if (AddActionsPage.instance) AddActionsPage.instance.reload_action_library(null);
				if (MyActionsPage.instance) MyActionsPage.instance.reload_actions();
			}, 
			error => {
				//
			}
		);
	}
	toggle_action_subscribed(action: any, invoke_on_success: any = null, change_action_object: boolean = true): void {
		if (change_action_object) action.subscribed = !action.subscribed;
		this.action_service.toggle_action_subscribed(action).subscribe(
			success => {
				if (ActionDrawerComponent.instance) ActionDrawerComponent.instance.reload_action_library();
				if (AddActionsPage.instance) AddActionsPage.instance.reload_action_library(null);
				if (MyActionsPage.instance) MyActionsPage.instance.reload_actions();
				if (invoke_on_success) invoke_on_success();
			}, 
			error => {
				//
			}
		);
	}
	notify_listeners(): void {
		if (ActionDrawerComponent.instance) ActionDrawerComponent.instance.reload_action_library();
		if (AddActionsPage.instance) AddActionsPage.instance.reload_action_library(null);
		if (MyActionsPage.instance) MyActionsPage.instance.reload_actions();
	}

	private headline_detail_popover: any = null;
	private async open_headline_detail_view(ev: any, headline: any) {
		this.headline_detail_popover = await this.popover_controller.create({
			component: HeadlineDetailComponent, 
			componentProps: {
		    	parent: this, 
		    	headline: headline, 
		    }, 
			cssClass: "headline_detail_popover",
			// event: ev,
			translucent: true
		});
		return await this.headline_detail_popover.present();
	}
	dismiss_headline_detail_view(): void {
		this.headline_detail_popover.dismiss().then(() => { this.headline_detail_popover = null; });
	}

	private get_filtered_headlines(): Array<any> {

		this.headlines.sort((headline1: any, headline2: any) => {

			var field1, field2;

			if (this.headline_sort_type == "date") {

				field1 = headline2.date; field2 = headline1.date;

			} else if (this.headline_sort_type == "author") {

				field1 = headline1.author; field2 = headline2.author;

			}

			if (field1 > field2) {
	           return 1;
	        } else if (field1 < field2) {
	            return -1;
	        } else return 0;

		});

		return this.headlines;

	}

	public reload_headlines(): void {
		this.headlines = null;
		this.headline_service.load_headlines().subscribe(
			headlines => {
				this.headlines = headlines;
				this.refreshers.forEach(refresher => {
					refresher.complete();
				});
				super.update_duplicate_slides();
			}, 
			error => {
				//
			}
		);
	}

}


