import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";

import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { AppSettings } from "../app-settings";

@Injectable()
export class SurveyService {

	constructor(
		private httpClient: HttpClient, 
		private router: Router
	) { }

	load_survey(): Observable<any> {
		return this.httpClient.get(
			AppSettings.API_ENDPOINT + "get-survey", 
			{withCredentials: true}
		).pipe(
			map(res => {
				return (res as any).survey;
			}),
			catchError(error => {
				// alert("An error occurred.");
				return null;
			})
		);
	}

	load_survey_and_clear_section(section_index_to_clear: number): Observable<any> {
		return this.httpClient.get(
			AppSettings.API_ENDPOINT + "get-survey?section_index_to_clear=" + section_index_to_clear, 
			{withCredentials: true}
		).pipe(
			map(res => {
				return (res as any).survey;
			}),
			catchError(error => {
				// alert("An error occurred.");
				return null;
			})
		);
	}

	save_survey_response(question_id: string, content: string): Observable<any> {
		return this.httpClient.post(
			AppSettings.API_ENDPOINT + "save-survey-response", 
			JSON.stringify({question_id: question_id, content: content}), 
			{headers: AppSettings.POST_HEADERS, withCredentials: true}
		).pipe(
			map(res => {
				return (res as any).success;
			}),
			catchError(error => {
				// alert("An error occurred.");
				return null;
			})
		);
	}

}


