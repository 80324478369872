import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";

import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { AppSettings } from "../app-settings";

@Injectable()
export class ActionService {

	constructor(
		private httpClient: HttpClient, 
		private router: Router
	) { }

	load_subscribed_action(action_id: number): Observable<any> {
		return this.httpClient.get(
			AppSettings.API_ENDPOINT + "get-subscribed-action?action_id=" + action_id, 
			{withCredentials: true}
		).pipe(
			map(res => {
				return (res as any).subscribed_action;
			}),
			catchError(error => {
				// alert("An error occurred.");
				return null;
			})
		);
	}

	save_subscribed_action_schedule(subscribed_action: any): Observable<any> {
		return this.httpClient.post(
			AppSettings.API_ENDPOINT + "save-subscribed-action-schedule", 
			JSON.stringify(subscribed_action), 
			{headers: AppSettings.POST_HEADERS, withCredentials: true}
		).pipe(
			map(res => {
				return (res as any).success;
			}),
			catchError(error => {
				// alert("An error occurred.");
				return null;
			})
		);
	}

	load_action_library(): Observable<any> {
		return this.httpClient.get(
			AppSettings.API_ENDPOINT + "get-action-library-for-user", 
			{withCredentials: true}
		).pipe(
			map(res => {
				var now_date = new Date();
			    var midnight_time = new Date(
				        now_date.getFullYear(),
				        now_date.getMonth(),
				        now_date.getDate(),
				        0, 0, 0).getTime();

				let action_library: Array<any> = (res as any).action_library;
				action_library.forEach(action => {
					if (action.hasOwnProperty("next_date") && action.next_date != null) {
						let next_date_parts = action.next_date.split("-");
						action.next_date = new Date(next_date_parts[0], next_date_parts[1] - 1, next_date_parts[2]);
					}
					if (action.hasOwnProperty("start_time") && action.start_time != null) action.start_time_adj = midnight_time + action.start_time * 1000;
					if (action.hasOwnProperty("end_time") && action.end_time != null) action.end_time_adj = midnight_time + action.end_time * 1000;
				});
				return action_library;
			}),
			catchError(error => {
				// console.log(error);
				// alert("An error occurred.");
				return null;
			})
		);
	}

	load_subscribed_actions(date: string): Observable<any> {
		return this.httpClient.get(
			AppSettings.API_ENDPOINT + "get-subscribed-actions-for-user?date=" + date, 
			{withCredentials: true}
		).pipe(
			map(res => {
				return (res as any).subscribed_actions;
			}),
			catchError(error => {
				// alert("An error occurred.");
				return null;
			})
		);
	}

	toggle_action_liked(action: any): Observable<any> {
		return this.httpClient.post(
			AppSettings.API_ENDPOINT + "toggle-action-liked", 
			JSON.stringify({action_id: action.id}), 
			{headers: AppSettings.POST_HEADERS, withCredentials: true}
		).pipe(
			map(res => {
				return (res as any).success;
			}),
			catchError(error => {
				// alert("An error occurred.");
				return null;
			})
		);
	}

	toggle_action_subscribed(action: any): Observable<any> {
		return this.httpClient.post(
			AppSettings.API_ENDPOINT + "toggle-action-subscribed", 
			JSON.stringify({action_id: action.id}), 
			{headers: AppSettings.POST_HEADERS, withCredentials: true}
		).pipe(
			map(res => {
				return (res as any).success;
			}),
			catchError(error => {
				// alert("An error occurred.");
				return null;
			})
		);
	}

	toggle_action_completed(action: any, date: string): Observable<any> {
		return this.httpClient.post(
			AppSettings.API_ENDPOINT + "toggle-action-completed", 
			JSON.stringify({action_id: action.id, date: date}), 
			{headers: AppSettings.POST_HEADERS, withCredentials: true}
		).pipe(
			map(res => {
				return (res as any).success;
			}),
			catchError(error => {
				// alert("An error occurred.");
				return null;
			})
		);
	}

}


