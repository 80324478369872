import { Injectable } from "@angular/core";

import { Storage } from "@ionic/storage-angular";

@Injectable()
export class StorageService {

    private _storage: Storage | null = null;

    constructor(private storage: Storage) {}

    async init() {
        if (this._storage != null) return
        const storage = await this.storage.create();
        this._storage = storage;
    }

    public async set(key: string, value: any): Promise<any> {
        await this.init();
        return await this._storage?.set(key, value);
    }

    public async get(key: string): Promise<any> {
        await this.init();
        return await this._storage?.get(key);
    }

    public async clear(): Promise<any> {
        await this.init();
        return await this._storage.clear();
    }

}


