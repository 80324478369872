export class Utils {

    private static array(n: number): Array<any> {
		return Array(n);
	}

	public static compute_rounded_str(x: number, num_decimal_places: number): string {
		let power_of_ten = Math.pow(10, num_decimal_places);
		return (Math.round(x * power_of_ten) / power_of_ten).toLocaleString();
	}

}


