import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpEvent, HttpHeaders, HttpResponse, HttpRequest, HttpHandler } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    public static Green: string = "";

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log("Adding Green header: " + AuthInterceptor._);
        const request_new = request.clone({ 
            headers: request.headers.set("Green", AuthInterceptor.Green),
        });
        return next.handle(request_new);
    }

}


