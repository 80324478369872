import { 
	Component, 
	Input, 
	OnInit, 
	ViewChild, 
} from "@angular/core";

import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core";

import { Dialog } from "@capacitor/dialog";

import { Utils } from "../utils";

import { ActionService } from "../services/action.service";
import { UserService } from "../services/user.service";

@Component({
	selector: "action-schedule",
	templateUrl: "./action-schedule.component.html",
	styleUrls: ["./action-schedule.component.css"]
})
export class ActionScheduleComponent implements OnInit {

	private UTILS: any = Utils;

	@Input() action: any = null;
	@Input() parent: any = null;
	@Input() display_close_button: boolean = false;

	private subscribed_action: any = null;

	private waiting_for_server_response: boolean = false;

	private LOCALIZATION_PREFIX: string = "action_schedule.";

	constructor(
		private action_service: ActionService, 
		private router: Router, 
		private translate_service: TranslateService, 
		private user_service: UserService, 
	) {
		// 
	}

	ngOnInit(): void {
		this.action_service.load_subscribed_action(this.action.id).subscribe(
			subscribed_action => {

				this.subscribed_action = subscribed_action;

				this.subscribed_action.dow = JSON.parse(this.subscribed_action.dow);
				for (var _ = 0; _ < this.subscribed_action.dow.length; _ ++) {
					this.subscribed_action.dow[_] = this.subscribed_action.dow[_] + "";
				}

				this.subscribed_action.dom = JSON.parse(this.subscribed_action.dom);
				for (var _ = 0; _ < this.subscribed_action.dom.length; _ ++) {
					this.subscribed_action.dom[_] = this.subscribed_action.dom[_] + "";
				}

			}, 
			error => {
				//
			}
		);
	}

	private close(save: boolean): void {
		if (save) {

			if (this.subscribed_action.start_date && this.subscribed_action.end_date && this.subscribed_action.end_date < this.subscribed_action.start_date) {
				this.translate_service.get(this.LOCALIZATION_PREFIX + "end_date_before_start_date").subscribe((res: string) => {
					Dialog.alert({
						title: "Error", 
						message: res
					});
				});
				return;
			}

			for (var _ = 0; _ < this.subscribed_action.dow.length; _ ++) {
				this.subscribed_action.dow[_] = parseInt(this.subscribed_action.dow[_]);
			}
			this.subscribed_action.dow = JSON.stringify(this.subscribed_action.dow);

			for (var _ = 0; _ < this.subscribed_action.dom.length; _ ++) {
				this.subscribed_action.dom[_] = parseInt(this.subscribed_action.dom[_]);
			}
			this.subscribed_action.dom = JSON.stringify(this.subscribed_action.dom);

			if (this.subscribed_action.scheduled_date) this.subscribed_action.scheduled_date = this.subscribed_action.scheduled_date.substring(0, 10);
			if (this.subscribed_action.start_date) this.subscribed_action.start_date = this.subscribed_action.start_date.substring(0, 10);
			if (this.subscribed_action.end_date) this.subscribed_action.end_date = this.subscribed_action.end_date.substring(0, 10);

			this.waiting_for_server_response = true;

			this.action_service.save_subscribed_action_schedule(this.subscribed_action).subscribe(
				success => {
					if (this.parent.set_subscribed_action) this.parent.set_subscribed_action(this.subscribed_action);
					this.parent.dismiss_modal();
					this.waiting_for_server_response = false;
				}, 
				error => {
					//
				}
			);

		} else {
			this.parent.dismiss_modal();
		}
	}

}


