import { Component, NgZone } from "@angular/core";

import { Router } from "@angular/router"

import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";

import { TranslateService } from "@ngx-translate/core";

import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { Dialog } from "@capacitor/dialog";
import { PermissionStatus, PushNotificationActionPerformed, PushNotifications, } from "@capacitor/push-notifications";

import { Network } from "@awesome-cordova-plugins/network/ngx";

import { AuthInterceptor } from "./auth-interceptor";

import { AppSettings } from "./app-settings";

import { AnnouncementService } from "./services/announcement.service";
import { StorageService } from "./services/storage.service";
import { UserService } from "./services/user.service";

import { AddActionsPage } from "./add-actions/add-actions.page";
import { CommunityPage } from "./community/community.page";
import { FootprintPage } from "./footprint/footprint.page";
import { WalletPage } from "./wallet/wallet.page";

declare var PTAGH_DEVICE: string;

@Component({
    selector: "app-root",
    templateUrl: "app.component.html",
    styleUrls: ["app.component.css"]
})
export class AppComponent {

    private LOCALIZATION_PREFIX: string = "announcement.";

    private APP_SETTINGS = AppSettings;

    private static NOTIFICATION_POST_LOGIN_URL: string = null;
    private static NOTIFICATION_TAB_INDEX: number = null;
    public static get_notification_post_login_url(): string {
        let copy = AppComponent.NOTIFICATION_POST_LOGIN_URL;
        AppComponent.NOTIFICATION_POST_LOGIN_URL = null;
        return copy;
    }
    public static get_notification_tab_index(): number {
        let copy = AppComponent.NOTIFICATION_TAB_INDEX;
        AppComponent.NOTIFICATION_TAB_INDEX = null;
        return copy;
    }

    private announcement: any = null;

    constructor(
        private announcement_service: AnnouncementService, 
        private in_app_browser: InAppBrowser, 
        private network: Network, 
        private platform: Platform, 
        private router: Router, 
        private splash_screen: SplashScreen,
        private status_bar: StatusBar, 
        private storage_service: StorageService, 
        private translate_service: TranslateService, 
        private user_service: UserService, 
        public zone: NgZone, 
    ) {
        this.initializeApp();
    }

    dismiss_announcement(): void {
        this.announcement = null;
    }

    async initializeApp() {

        setTimeout(() => {
            if (this.network.type == this.network.Connection.NONE) {
                Dialog.alert({
                    title: "Connection Error", 
                    message: "SNAPP can't connect to the database. Do you have a network connection?", 
                });
            }
        }, 5000);

        let _this = this;

        // TODO: Remove this!
        this.translate_service.setDefaultLang("en-US");
        // this.translate_service.setDefaultLang(navigator.language);
        // this.translate_service.use("LANG_CODE");

        let Green = await this.storage_service.get("login.Green");
        if (Green) AuthInterceptor.Green = Green;

        this.platform.ready().then(() => {
            this.status_bar.styleDefault();
            this.splash_screen.hide();
        });

        this.router.navigate([ "/" ]);
        let destination = window.location.pathname;
        if (destination != "/") {
            this.user_service.load_logged_in_user().subscribe(
                user => {
                    if (user == null) {
                        // Do nothing.
                    } else {
                        this.router.navigate([ destination ]);
                    }
                }, 
                error => {
                    //
                }
            );
        }

        (window as any).open_external_global = function(url: string) {
            _this.in_app_browser.create(url, "_system")
        }

        this.announcement_service.load_announcements().subscribe(
            announcements => {
                if (announcements.length > 0) this.announcement = announcements[0];
            }, 
            error => {
                //
            }
        );

        PushNotifications.addListener("pushNotificationActionPerformed", async (notification: PushNotificationActionPerformed) => {

            console.log("Push notification received:");
            console.log(notification.notification.data);

            this.zone.run(() => {

                if (notification.notification.data.hasOwnProperty("type")) {

                    if (notification.notification.data.type == "new_offer") {

                        let destination = "/tabs/wallet";

                        if (WalletPage.instance == null) {
                            AppComponent.NOTIFICATION_TAB_INDEX = 0;
                        } else {
                            AppComponent.NOTIFICATION_TAB_INDEX = null;
                            WalletPage.instance.reload_available_offers();
                            WalletPage.instance.slide_to(0);
                        }

                        if (this.user_service.get_logged_in_user() != null && this.user_service.get_logged_in_user().has_finished_setup) {
                            AppComponent.NOTIFICATION_POST_LOGIN_URL = null;
                            this.router.navigate( [ destination ] );
                        } else {
                            AppComponent.NOTIFICATION_POST_LOGIN_URL = destination;
                        }

                    } else if (notification.notification.data.type == "new_event") {

                        let destination = "/tabs/add-actions";

                        if (AddActionsPage.instance == null) {
                            AppComponent.NOTIFICATION_TAB_INDEX = 11;
                        } else {
                            AppComponent.NOTIFICATION_TAB_INDEX = null;
                            AddActionsPage.instance.reload_action_library(11);
                        }

                        if (this.user_service.get_logged_in_user() != null && this.user_service.get_logged_in_user().has_finished_setup) {
                            AppComponent.NOTIFICATION_POST_LOGIN_URL = null;
                            this.router.navigate( [ destination ] );
                        } else {
                            AppComponent.NOTIFICATION_POST_LOGIN_URL = destination;
                        }

                    } else if (notification.notification.data.type == "new_headline") {

                        let destination = "/tabs/community";

                        if (CommunityPage.instance == null) {
                            AppComponent.NOTIFICATION_TAB_INDEX = 0;
                        } else {
                            AppComponent.NOTIFICATION_TAB_INDEX = null;
                            CommunityPage.instance.reload_headlines();
                            CommunityPage.instance.slide_to(0);
                        }

                        if (this.user_service.get_logged_in_user() != null && this.user_service.get_logged_in_user().has_finished_setup) {
                            AppComponent.NOTIFICATION_POST_LOGIN_URL = null;
                            this.router.navigate( [ destination ] );
                        } else {
                            AppComponent.NOTIFICATION_POST_LOGIN_URL = destination;
                        }

                    } else if (notification.notification.data.type == "new_action") {

                        let destination = "/tabs/add-actions";

                        var tab_index;
                        if (notification.notification.data.resource_id == 12) {
                            tab_index = 5;
                        } else if (notification.notification.data.resource_id == 13) {
                            tab_index = 6;
                        } else if (notification.notification.data.resource_id == 14) {
                            tab_index = 9;
                        } else if (notification.notification.data.resource_id == 15) {
                            tab_index = 7;
                        } else if (notification.notification.data.resource_id == 16) {
                            tab_index = 10;
                        } else if (notification.notification.data.resource_id == 17) {
                            tab_index = 8;
                        }

                        if (AddActionsPage.instance == null) {
                            AppComponent.NOTIFICATION_TAB_INDEX = tab_index;
                        } else {
                            AppComponent.NOTIFICATION_TAB_INDEX = null;
                            AddActionsPage.instance.reload_action_library(tab_index);
                        }

                        if (this.user_service.get_logged_in_user() != null && this.user_service.get_logged_in_user().has_finished_setup) {
                            AppComponent.NOTIFICATION_POST_LOGIN_URL = null;
                            this.router.navigate( [ destination ] );
                        } else {
                            AppComponent.NOTIFICATION_POST_LOGIN_URL = destination;
                        }

                    } else if (notification.notification.data.type == "expiring_offer") {

                        let destination = "/tabs/wallet";

                        if (WalletPage.instance == null) {
                            AppComponent.NOTIFICATION_TAB_INDEX = 2;
                        } else {
                            AppComponent.NOTIFICATION_TAB_INDEX = null;
                            WalletPage.instance.reload_available_offers();
                            WalletPage.instance.slide_to(2);
                        }

                        if (this.user_service.get_logged_in_user() != null && this.user_service.get_logged_in_user().has_finished_setup) {
                            AppComponent.NOTIFICATION_POST_LOGIN_URL = null;
                            this.router.navigate( [ destination ] );
                        } else {
                            AppComponent.NOTIFICATION_POST_LOGIN_URL = destination;
                        }

                    } else if (notification.notification.data.type == "baseline") {

                        let destination = "/tabs/footprint";

                        if (FootprintPage.instance == null) {
                            AppComponent.NOTIFICATION_TAB_INDEX = 0;
                        } else {
                            AppComponent.NOTIFICATION_TAB_INDEX = null;
                            FootprintPage.instance.slide_to(0);
                        }

                        if (this.user_service.get_logged_in_user() != null && this.user_service.get_logged_in_user().has_finished_setup) {
                            AppComponent.NOTIFICATION_POST_LOGIN_URL = null;
                            this.router.navigate( [ destination ] );
                        } else {
                            AppComponent.NOTIFICATION_POST_LOGIN_URL = destination;
                        }

                    } else if (notification.notification.data.type == "streak") {

                        let destination = "/tabs/my-actions";

                        if (this.user_service.get_logged_in_user() != null && this.user_service.get_logged_in_user().has_finished_setup) {
                            AppComponent.NOTIFICATION_POST_LOGIN_URL = null;
                            this.router.navigate( [ destination ] );
                        } else {
                            AppComponent.NOTIFICATION_POST_LOGIN_URL = destination;
                        }

                    } else if (notification.notification.data.type == "rank") {

                        let destination = "/tabs/my-actions";

                        if (this.user_service.get_logged_in_user() != null && this.user_service.get_logged_in_user().has_finished_setup) {
                            AppComponent.NOTIFICATION_POST_LOGIN_URL = null;
                            this.router.navigate( [ destination ] );
                        } else {
                            AppComponent.NOTIFICATION_POST_LOGIN_URL = destination;
                        }

                    } else if (notification.notification.data.type == "no_actions_added") {

                        let destination = "/tabs/add-actions";

                        if (this.user_service.get_logged_in_user() != null && this.user_service.get_logged_in_user().has_finished_setup) {
                            AppComponent.NOTIFICATION_POST_LOGIN_URL = null;
                            this.router.navigate( [ destination ] );
                        } else {
                            AppComponent.NOTIFICATION_POST_LOGIN_URL = destination;
                        }

                    }

                }

            });

        });

    }

}


