import { 
	Component, 
	ElementRef, 
	Input, 
	OnInit, 
	ViewChild, 
	ViewChildren,
} from "@angular/core";

import { trigger, state, style, animate, transition } from "@angular/animations";

import { ActivatedRoute, Router } from "@angular/router";

import { IonInput, IonRefresher, IonSlides, ModalController } from "@ionic/angular";

import { TranslateService } from "@ngx-translate/core";

import { Utils } from "../utils";

import { ActionService } from "../services/action.service";
import { UserService } from "../services/user.service";

import { DualSliderPage } from "../dual-slider.page";

import { ActionDetailComponent } from "../action-detail/action-detail.component";
import { ActionDrawerComponent } from "../my-actions/action-drawer.component";
import { ActionScheduleComponent } from "../action-detail/action-schedule.component";
import { AppComponent } from "../app.component";
import { CommunityPage } from "../community/community.page";
import { MyActionsPage } from "../my-actions/my-actions.page";

@Component({
	selector: "add-actions",
	templateUrl: "./add-actions.page.html",
	styleUrls: ["./add-actions.page.css"], 
	animations: [
	    trigger("slide_out", [
			transition(":leave", [
				animate("200ms linear", style({transform: "translateX(-100%)"}))
			]), 
		]), 
	], 
})
export class AddActionsPage extends DualSliderPage implements OnInit {

	private UTILS: any = Utils;

	public static instance: AddActionsPage = null;

	LOCALIZATION_PREFIX: string = "add_actions.";
	private title: string = this.LOCALIZATION_PREFIX + "title";

	NAV_ITEMS = [
		{
			icon_class: "infinite_nav_icon_suggested", 
			label_long: "nav_label_long_suggested", 
			label_short: "nav_label_short_suggested", 
			filter_type: "suggested", 
		}, 
		{
			icon_class: "infinite_nav_icon_bang", 
			label_long: "nav_label_long_bang", 
			label_short: "nav_label_short_bang", 
			filter_type: "bang", 
		}, 
		{
			icon_class: "action_icon_hog", 
			label_long: "nav_label_long_hogs", 
			label_short: "nav_label_short_hogs", 
			filter_type: "hogs", 
		}, 
		{
			icon_class: "infinite_nav_icon_fav", 
			label_long: "nav_label_long_fav", 
			label_short: "nav_label_short_fav", 
			filter_type: "fav", 
		}, 
		{
			icon_class: "action_icon_compare", 
			label_long: "nav_label_long_compare", 
			label_short: "nav_label_short_compare", 
			filter_type: "compare", 
		}, 
		{
			icon_class: "action_icon_resource_12", 
			label_long: "nav_label_long_heat", 
			label_short: "nav_label_short_heat", 
			resource_id: 12, 
		}, 
		{
			icon_class: "action_icon_resource_13", 
			label_long: "nav_label_long_electricity", 
			label_short: "nav_label_short_electricity", 
			resource_id: 13, 
		}, 
		{
			icon_class: "action_icon_resource_15", 
			label_long: "nav_label_long_food", 
			label_short: "nav_label_short_food", 
			resource_id: 15, 
		}, 
		{
			icon_class: "action_icon_resource_17", 
			label_long: "nav_label_long_transport", 
			label_short: "nav_label_short_transport", 
			resource_id: 17, 
		}, 
		{
			icon_class: "action_icon_resource_14", 
			label_long: "nav_label_long_water", 
			label_short: "nav_label_short_water", 
			resource_id: 14, 
		}, 
		{
			icon_class: "action_icon_resource_16", 
			label_long: "nav_label_long_goods", 
			label_short: "nav_label_short_goods", 
			resource_id: 16, 
		}, 
		{
			icon_class: "infinite_nav_icon_events", 
			label_long: "nav_label_long_events", 
			label_short: "nav_label_short_events", 
			filter_type: "events", 
		}, 
		{
			icon_class: "infinite_nav_icon_misc", 
			label_long: "nav_label_long_misc", 
			label_short: "nav_label_short_misc", 
			filter_type: "misc", 
		}, 
	];

	private NAV_SLIDER_OPTIONS: any = {
		loop: true, 
		slidesPerView: "auto", 
		slideToClickedSlide: true, 
		slidesOffsetAfter: 10, 
		slidesOffsetBefore: 10, 
		spaceBetween: 10, 
		watchSlidesProgress: true, 
		watchSlidesVisibility: true, 
	};
	MAIN_SLIDER_SPEED = 300;
	private MAIN_SLIDER_OPTIONS: any = {
		loop: true, 
		speed: this.MAIN_SLIDER_SPEED, 
	};

	private action_library: Array<any> = null;

	tabs_header_collapsible: boolean = true;
	tabs_header_collapsed: boolean = false;

	INIT_BANNER_HEIGHT: number = 126;
	banner_height: number = this.INIT_BANNER_HEIGHT;

	INIT_MAIN_SLIDES_HEIGHT_ADJ = 230; // We add the height of #search_bar in ngOnInit. 230 is the sum of the heights of all the other non-slide elements in the primary ion-content.
	main_slides_height_adj: number = this.INIT_MAIN_SLIDES_HEIGHT_ADJ;

	last_nav_index: number = this.NAV_ITEMS.length;

	private action_sort_type: string = "alpha";

	private search_bar_value: string = "";

	@ViewChild("search_bar") search_bar: IonInput;
	@ViewChild("search_bar_container") search_bar_container: ElementRef;
	@ViewChild("nav_slides") nav_slides: IonSlides;
	@ViewChild("main_slides") main_slides: IonSlides;

	@ViewChildren(IonRefresher) refreshers: Array<IonRefresher>;

	private modal: any = null;

	constructor(
		private action_service: ActionService, 
		private modal_controller: ModalController, 
		private route: ActivatedRoute, 
		private router: Router, 
		private translate_service: TranslateService, 
		private user_service: UserService, 
	) {
		super(translate_service);
	}

	ngOnInit(): void {

		AddActionsPage.instance = this;

		// Hack because IonInput doesn't appear to immediately render:
		let _this = this;
		let _ = setInterval(function() {
			if (_this.search_bar_container.nativeElement.offsetHeight > 0) {
				_this.main_slides_height_adj += _this.search_bar_container.nativeElement.offsetHeight;
				clearInterval(_);
			}
 		}, 100);

		super.translate_nav_labels();
		this.reload_action_library(null);

		this.route.params.subscribe(params => {
			if (params.hasOwnProperty("tab_index")) setTimeout(function() {_this.slide_to(+(params.tab_index.substring(1,2)));}, 0);
		});

		let tab_index = AppComponent.get_notification_tab_index();
		if (tab_index != null) {
			setTimeout(() => {
				this.slide_to(tab_index);
			}, 0);
		}

	}

	private clear_selected() {
		let _this = this;
		this.action_library.forEach(action => {
			_this.unselect(action);
		});
	}

	private unselect(action: any) {
		action.slide_out = true;
		setTimeout(function() {
			action.selected = false;
			action.slide_out = false;
		}, 250);
	}

	private get_language(): string {
		return navigator.language;
	}

	on_slide_change(current_main_index: number): void {
		let current_nav_item = this.NAV_ITEMS[(current_main_index - 1 + this.NAV_ITEMS.length) % this.NAV_ITEMS.length];
		if (current_nav_item.filter_type == "events" && (this.action_sort_type == "pts" || this.action_sort_type == "cost")) {
			this.action_sort_type = "date";
		} else if (current_nav_item.filter_type != "events" && this.action_sort_type == "date") {
			this.action_sort_type = "alpha";
		}
	}

	private async display_action_detail_modal(ev: any, action: any) {
		if (ev.target.classList.contains("action_selected_icon")) return;
		if (ev.target.classList.contains("action_icon_heart_empty")) return;
		if (ev.target.classList.contains("action_icon_heart_filled")) return;
		if (ev.target.classList.contains("action_icon_heart_empty")) return;
		if (ev.target.classList.contains("action_icon_plus")) return;
		if (ev.target.classList.contains("action_icon_minus")) return;

		this.modal = await this.modal_controller.create({
			component: ActionDetailComponent,
			componentProps: {
				action: action, 
		    	parent: this, 
		    }, 
			cssClass: "action_detail", 
		});
		return await this.modal.present();
	}
	private subscribe(action: any) {
		action.waiting_for_server_response = true;
		if (action.is_event) {
			this.toggle_action_subscribed(action, function() {
				action.waiting_for_server_response = false;
				action.subscribed = true;
			}, false);
		} else if (action.is_schedule_overridable) {
			let _this = this;
			this.toggle_action_subscribed(action, async function() {
				_this.modal = await _this.modal_controller.create({
					component: ActionScheduleComponent,
					componentProps: {
						action: action, 
				    	parent: _this, 
				    	display_close_button: false, 
				    }, 
					cssClass: "action_schedule", 
				});
				await _this.modal.present();
				action.waiting_for_server_response = false;
				action.subscribed = true;
			}, false);
		} else {
			action.slide_out = true;
			this.toggle_action_subscribed(action, function() {
				action.waiting_for_server_response = false;
				action.subscribed = true;
				action.slide_out = false;
			}, false);
		}
	}
	dismiss_modal(): void {
		if (this.modal) this.modal.dismiss().then(() => { this.modal = null; });
	}

	private search_bar_active: boolean = false;
	private activate_search_bar(): void {
		this.search_bar_active = true;
		this.search_bar.setFocus();
	}
	private search_bar_focus(): void {
		// this.nav_slides.slideTo(3);
	}
	private search_bar_blur(): void {
		if (this.search_bar_value == "") this.search_bar_active = false;
	}

	get_filtered_action_library(nav_item: any): Array<any> {

		var actions = [];

		if (nav_item.hasOwnProperty("resource_id")) {

			this.action_library.forEach(action => {
				if (action.resource_id == nav_item.resource_id) actions.push(action);
			});

		} else if (nav_item.hasOwnProperty("filter_type")) {

			if (nav_item.filter_type == "bang") {

				this.action_library.forEach(action => {
					if (action.bang_bucket == 2) actions.push(action);
				});

			} else if (nav_item.filter_type == "compare") {

				this.action_library.forEach(action => {
					if (action.selected) actions.push(action);
				});

			} else if (nav_item.filter_type == "events") {

				this.action_library.forEach(action => {
					if (action.is_event && action.hasOwnProperty("next_date")) actions.push(action);
				});

			} else if (nav_item.filter_type == "fav") {

				this.action_library.forEach(action => {
					if (action.liked) actions.push(action);
				});

			} else if (nav_item.filter_type == "hogs") {

				this.action_library.forEach(action => {
					if (action.is_hog_action) actions.push(action);
				});

			} else if (nav_item.filter_type == "misc") {

				this.action_library.forEach(action => {
					if (action.is_event == 0 && (action.resource_id == null || action.resource_id < 0)) actions.push(action);
				});

			} else if (nav_item.filter_type == "suggested") {

				this.action_library.forEach(action => {
					if (action.is_suggested) actions.push(action);
				});

			}

		}

		var actions_copy; // utility var used below

		// Filter by this.search_bar_value:
		let search_str = this.search_bar_value.toLowerCase().trim();
		if (search_str != "") {
			actions_copy = actions;
			actions = [];
			actions_copy.forEach(action => {
				if (action.name.toLowerCase().indexOf(search_str) > -1) {
					actions.push(action);
				} else if (action.is_event == 1 && action.hasOwnProperty("address_line_1") && action.address_line_1 != null && action.address_line_1.toLowerCase().indexOf(search_str) > -1) {
					actions.push(action);
				}
			});
		}

		// Filter by subscription status:
		if (nav_item.filter_type != "events") {
			actions_copy = actions;
			actions = [];
			actions_copy.forEach(action => {
				if (!action.subscribed) actions.push(action);
			});
		}

		actions.sort((action1: any, action2: any) => {

			var field1, field2;

			if (this.action_sort_type == "alpha") {

				field1 = action1.name; field2 = action2.name;

			} else if (this.action_sort_type == "cost") {

				field1 = action1.cost_bucket; field2 = action2.cost_bucket;

			} else if (this.action_sort_type == "date") {

				field1 = 0;
				if (action1.hasOwnProperty("next_date") && action1.next_date != null) field1 += action1.next_date.getTime();
				if (action1.hasOwnProperty("start_time") && action1.start_time != null) field1 += action1.start_time * 1000;
				field2 = 0;
				if (action2.hasOwnProperty("next_date") && action2.next_date != null) field2 += action2.next_date.getTime();
				if (action2.hasOwnProperty("start_time") && action2.start_time != null) field2 += action2.start_time * 1000;

			} else if (this.action_sort_type == "pts") {

				field1 = action2.num_points; field2 = action1.num_points;

			}

			if (field1 > field2) {
	           return 1;
	        } else if (field1 < field2) {
	            return -1;
	        } else return 0;

		});

		return actions;

	}

	private get_action_content_width(nav_item: any): string {
		if (nav_item.filter_type == "events") {
			return (window.innerWidth - 115) + "px";
		} else {
			return (window.innerWidth - 140) + "px";
		}
	}

	private get_action_num_points_str(action: any): string {
		return Math.round(action.num_points).toLocaleString();
	}
	private get_action_impact_str(action: any): string {
		return Math.round(action.impact).toLocaleString();
	}

	public reload_action_library(slide_to: number): void {
		this.action_library = null;
		this.action_service.load_action_library().subscribe(
			action_library => {
				this.action_library = action_library;
				this.refreshers.forEach(refresher => {
					refresher.complete();
				});
				super.update_duplicate_slides();
				if (slide_to != null) this.slide_to(slide_to);
			}, 
			error => {
				//
			}
		);
	}
	
	toggle_action_liked(action: any): void {
		action.liked = !action.liked;
		this.action_service.toggle_action_liked(action).subscribe(
			success => {
				if (ActionDrawerComponent.instance) ActionDrawerComponent.instance.reload_action_library();
				if (CommunityPage.instance) CommunityPage.instance.reload_action_library();
				if (MyActionsPage.instance) MyActionsPage.instance.reload_actions();
			}, 
			error => {
				//
			}
		);
	}

	toggle_action_subscribed(action: any, invoke_on_success: any = null, change_action_object: boolean = true): void {
		if (change_action_object) action.subscribed = !action.subscribed;
		this.action_service.toggle_action_subscribed(action).subscribe(
			success => {
				if (ActionDrawerComponent.instance) ActionDrawerComponent.instance.reload_action_library();
				if (CommunityPage.instance) CommunityPage.instance.reload_action_library();
				if (MyActionsPage.instance) MyActionsPage.instance.reload_actions();
				if (invoke_on_success) invoke_on_success();
			}, 
			error => {
				//
			}
		);
	}

	notify_listeners(): void {
		if (ActionDrawerComponent.instance) ActionDrawerComponent.instance.reload_action_library();
		if (CommunityPage.instance) CommunityPage.instance.reload_action_library();
		if (MyActionsPage.instance) MyActionsPage.instance.reload_actions();
	}

}


