import { 
	Component, 
	Input, 
	OnInit, 
	ViewChild, 
	ViewChildren,
} from "@angular/core";

import { Router } from "@angular/router";

import { IonRefresher, IonSlides, ModalController, PopoverController } from "@ionic/angular";

import { TranslateService } from "@ngx-translate/core";

import { GamesService } from "../services/games.service";
import { UserService } from "../services/user.service";

import { DualSliderPage } from "../dual-slider.page";

@Component({
	selector: "games",
	templateUrl: "./games.page.html",
	styleUrls: ["./games.page.css"]
})
export class GamesPage extends DualSliderPage implements OnInit {

	public static instance: GamesPage = null;

	LOCALIZATION_PREFIX: string = "games.";
	private title: string = this.LOCALIZATION_PREFIX + "title";

	NAV_ITEMS = [
		{
			icon_class: "infinite_nav_icon_stopwatch", 
			label_long: "nav_label_long_point_challenge", 
			label_short: "nav_label_short_point_challenge", 
		}, 
		{
			icon_class: "infinite_nav_icon_people", 
			label_long: "nav_label_long_friends", 
			label_short: "nav_label_short_friends", 
		}, 
		{
			icon_class: "infinite_nav_icon_trophy", 
			label_long: "nav_label_long_rank", 
			label_short: "nav_label_short_rank", 
		}, 
		{
			icon_class: "", 
			label_long: "nav_label_long_tbd", 
			label_short: "nav_label_short_tbd", 
		}, 
	];

	private NAV_SLIDER_OPTIONS: any = {
		loop: true, 
		slidesPerView: 4, 
		slideToClickedSlide: true, 
		// slidesOffsetAfter: 10, 
		// slidesOffsetBefore: 10, 
		watchSlidesProgress: true, 
		watchSlidesVisibility: true, 
	};
	MAIN_SLIDER_SPEED = 300;
	private MAIN_SLIDER_OPTIONS: any = {
		loop: true, 
		speed: this.MAIN_SLIDER_SPEED, 
	};

	tabs_header_collapsible: boolean = false;
	tabs_header_collapsed: boolean = false;

	INIT_BANNER_HEIGHT: number = 126;
	banner_height: number = this.INIT_BANNER_HEIGHT;

	INIT_MAIN_SLIDES_HEIGHT_ADJ = 230;
	main_slides_height_adj: number = this.INIT_MAIN_SLIDES_HEIGHT_ADJ;

	last_nav_index: number = this.NAV_ITEMS.length;

	private challenge_household_card_circle_index: number = 0;
	private challenge_nbd_card_circle_index: number = 0;

	@ViewChild("nav_slides") nav_slides: IonSlides;
	@ViewChild("main_slides") main_slides: IonSlides;

	@ViewChildren(IonRefresher) refreshers: Array<IonRefresher>;

	private household_point_challenge_data: any = null;
	private nbd_point_challenge_data: any = null;
	private city_point_challenge_data: any = null;

	constructor(
		private games_service: GamesService, 
		private modal_controller: ModalController, 
		private popover_controller: PopoverController, 
		private router: Router, 
		private translate_service: TranslateService, 
		private user_service: UserService, 
	) {
		super(translate_service);
	}

	ngOnInit(): void {
		GamesPage.instance = this;

		super.translate_nav_labels();
		this.reload_games_data();
	}

	public reload_games_data(): void {
		this.games_service.load_games_data().subscribe(
			games_data => {
				if (!games_data.success) {
					// Handle?
				} else {
					this.household_point_challenge_data = games_data.household_point_challenge_data;
					this.nbd_point_challenge_data = games_data.nbd_point_challenge_data;
					this.city_point_challenge_data = games_data.city_point_challenge_data;
					this.refreshers.forEach(refresher => {
						refresher.complete();
					});
					super.update_duplicate_slides();
				}
			}, 
			error => {
				//
			}
		);
	}

	private get_month_str(): string {
		let d = new Date();
		return d.toLocaleString("default", {month: "long"}) + " " + d.getFullYear();
	}

	private get_days_remaining_in_month(): number {
		let now = new Date();
        let days_in_month = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
        return days_in_month > now.getDate() ? days_in_month - now.getDate() : 0;
	}

	private compute_challenge_household_card_circle_z_index(card_circle_index: number) {
		if (card_circle_index == this.challenge_household_card_circle_index) return 5;
		if (card_circle_index == 0) return 3;
		if (card_circle_index == 1) return 4;
		if (card_circle_index == 2) return 3;
	}

}


